const INITIAL_STATE = {
  position: "horizontal",
  propControleReferencia: "cicloProjeto"
};

export default function SideNav(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_VIEWMODE":
      return { ...state, position: action.direction };
    case "SET_PROP_CONTROLE_REFERENCIA":
      return { ...state, propControleReferencia: action.payload };
    default:
      return state;
  }
}
