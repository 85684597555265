import moment from "moment";

export const formatData = (isoString) => {
  if (isoString) {
    return moment(isoString).format("DD/MM/YYYY hh:mm:ss");
  }
};

export const formatData2 = (isoString) => {
  if (isoString) {
    return moment(isoString).format("DD/MM/YYYY");
  }
};

export const formatDataReduced = (isoString) => {
  if (isoString) {
    const data = new Date(isoString).toISOString();
    const options = {
      month: "numeric",
      year: "numeric",
    };
    const date = new Date(data);
    const formatDate = new Intl.DateTimeFormat("pt-BR", options).format(date);
    return formatDate;
  }
};