import React, { useState, useEffect } from "react";

import GlobalStyle from "../../styles/global";
import api from "../../services/apiBender";

const App = ({ windowLocation }) => {
    const [cssCustom, setCssCustom] = useState(JSON.parse(localStorage.getItem("ls-objTemplate")));

    document.title = (`${cssCustom?.nomCliente ?? "BTB Soluções"} - Portal`);

    useEffect(() => {
        const fetchData = async (dscSiglaCliente) => {
            let objResult = {
                "nomCliente": null,
                "text-black-sidebar": null,
                "layout-navbar": null,
                "btn-primary": null,
                "btn-primary-focus": null,
                "i-text-primary": null,
                "table-thead": null,
                "pagination": null,
                "progress-bar": null,
                "anchor-focus": null,
                "success": null,
                "logo-src": null,
                "logo-size-interno": null,
                "logo-size-externo": null,
                "arrGraphicsColors": null,
            }

            try {
                const { data: objTemplate } = await api.get(`v1/configCSSTemplatePortal?c=${dscSiglaCliente}`);

                if (objTemplate) {
                    objResult = JSON.parse(objTemplate?.dscJsonConfig);
                }

            } catch (error) {
                console.log("error: ", error);
            }

            setCssCustom(objResult);

            if (objResult && Object.keys(objResult).length > 0) {
                localStorage.setItem("ls-objTemplate", JSON.stringify(objResult));
                windowLocation.reload();
            }
        }

        // if (windowLocation?.pathname === "/" &&
        //     !localStorage.getItem("Token") === true &&
        //     !localStorage.getItem("ls-objTemplate") === false &&
        //     !windowLocation?.search
        // ) {
        //     setCssCustom(null);
        //     localStorage.removeItem("ls-objTemplate");
        // }
        //else

        if (windowLocation?.pathname === "/" && windowLocation?.search) {
            const urlParams = new URLSearchParams(windowLocation.search);
            const dscSiglaCliente = urlParams.get("c");

            if (dscSiglaCliente === "BTB") {
                setCssCustom(null);
                localStorage.removeItem("ls-objTemplate");
            }
            else if (dscSiglaCliente && !cssCustom) {
                fetchData(dscSiglaCliente);
            }
        }
    }, [cssCustom, windowLocation]);

    return (
        <>
            <GlobalStyle cssCustom={cssCustom} />
        </>
    )
}

export default App;