const INITIAL_STATE = {
  data: [],
  total_fatura: "",
  visible: true,
};

export default function DetalhesDaFatura(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "DETALHES_FATURA":
      return { ...state, data: action.detalhes };
    case "TOTAL_FATURA":
      return { ...state, total_fatura: action.totalFatura };
    case "VISIBLE":
      return { ...state, visible: action.visible };
    default:
      return state;
  }
}
