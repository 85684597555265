import styled, { keyframes } from "styled-components";

export const Card = styled.div``;

export const Description = styled.small`
  font-size: 12px;
  text-transform: capitalize;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Carregando = styled.h3`
  color: #eee;
`;

export const shimmer = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
`;

export const LoadingTitle = styled.div`
  width: 80%;
  height: 15px;
  background-image: linear-gradient(
    -90deg,
    #e7edf1 0%,
    #f8f8f8 50%,
    #e7edf1 100%
  );
  background-size: 400% 400%;
  animation: ${shimmer} 1.2s ease-in-out infinite;
`;

export const LoadingDescription = styled.div`
  width: 50%;
  height: 15px;
  background-image: linear-gradient(
    -90deg,
    #e7edf1 0%,
    #f8f8f8 50%,
    #e7edf1 100%
  );
  background-size: 400% 400%;
  animation: ${shimmer} 1.2s ease-in-out infinite;
  margin-top: 7px;
`;

export const LoadingIcon = styled.div`
  width: 50px;
  height: 50px;
  background-image: linear-gradient(
    -90deg,
    #e7edf1 0%,
    #f8f8f8 50%,
    #e7edf1 100%
  );
  background-size: 400% 400%;
  animation: ${shimmer} 1.2s ease-in-out infinite;
`;

export const TitleHeader = styled.div`
  width: 150px;
  height: 25px;
  background-image: linear-gradient(
    -90deg,
    #e7edf1 0%,
    #f8f8f8 50%,
    #e7edf1 100%
  );
  background-size: 400% 400%;
  animation: ${shimmer} 1.2s ease-in-out infinite;
`;

export const TextLoading = styled.span`
  font-size: 16px;
  color: #eee;
`;

export const TitleCardMenu = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-image: linear-gradient(
    -90deg,
    #e7edf1 0%,
    #f8f8f8 50%,
    #e7edf1 100%
  );
  background-size: 400% 400%;
  animation: ${shimmer} 1.2s ease-in-out infinite;
`;

export const CardHeaderLeft = styled.div`
  width: 50%;
  height: 35px;
  background-image: linear-gradient(
    -90deg,
    #e7edf1 0%,
    #f8f8f8 50%,
    #e7edf1 100%
  );
  background-size: 400% 400%;
  animation: ${shimmer} 1.2s ease-in-out infinite;
`;

export const CardHeaderRigth = styled.div`
  width: 100%;
  height: 35px;
  background-image: linear-gradient(
    -90deg,
    #e7edf1 0%,
    #f8f8f8 50%,
    #e7edf1 100%
  );
  background-size: 400% 400%;
  animation: ${shimmer} 1.2s ease-in-out infinite;
`;

export const Columns = styled.div`
  width: 100%;
  height: 35px;
  background-image: linear-gradient(
    -90deg,
    #e7edf1 0%,
    #f8f8f8 50%,
    #e7edf1 100%
  );
  background-size: 400% 400%;
  animation: ${shimmer} 1.2s ease-in-out infinite;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TDBody = styled.div`
  width: 100%;
  height: 350px;
  background-image: linear-gradient(
    -90deg,
    #e7edf1 0%,
    #f8f8f8 50%,
    #e7edf1 100%
  );
  background-size: 400% 400%;
  animation: ${shimmer} 1.2s ease-in-out infinite;
`;

export const BadgeShimmer = styled.div`
  width: 50px;
  height: 19px;
  background-image: linear-gradient(
    -90deg,
    #e7edf1 0%,
    #f8f8f8 50%,
    #e7edf1 100%
  );
  background-size: 400% 400%;
  animation: ${shimmer} 1.2s ease-in-out infinite;
`;

export const DetailsHeader = styled.div`
  width: 180px;
  height: 35px;
  background-image: linear-gradient(
    -90deg,
    #e7edf1 0%,
    #f8f8f8 50%,
    #e7edf1 100%
  );
  background-size: 400% 400%;
  animation: ${shimmer} 1.2s ease-in-out infinite;
`;

export const DetailsBody = styled.div`
  width: 100%;
  height: 35px;
  background-image: linear-gradient(
    -90deg,
    #e7edf1 0%,
    #f8f8f8 50%,
    #e7edf1 100%
  );
  background-size: 400% 400%;
  animation: ${shimmer} 1.2s ease-in-out infinite;
`;

export const DuaListBody = styled.div`
  width: 100%;
  height: 240px;
  background-image: linear-gradient(
    -90deg,
    #e7edf1 0%,
    #f8f8f8 50%,
    #e7edf1 100%
  );
  background-size: 400% 400%;
  animation: ${shimmer} 1.2s ease-in-out infinite;
`;

export const DuaListHeader = styled.div`
  width: 100%;
  height: 30px;
  background-image: linear-gradient(
    -90deg,
    #e7edf1 0%,
    #f8f8f8 50%,
    #e7edf1 100%
  );
  background-size: 400% 400%;
  animation: ${shimmer} 1.2s ease-in-out infinite;
`;
