const INITIAL_STATE = {
  devices: [],
  address: {},
  paymentMethod: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ADD_DEVICE":
      return { ...state, devices: action.devices };
    case "ADD_ADDRESS":
      return { ...state, address: action.address };
    case "ADD_PAYMENT_METHOD":
      return { ...state, paymentMethod: action.paymentMethod };
    default:
      return state;
  }
}
