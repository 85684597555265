import axios from "axios";
import cookie from "react-cookies";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_DNS}/`, //"http://159.65.221.157:30463/api/"
});

//"http://159.65.221.157:30463/api/"
//https://dev.btbtelecom.com/api/
// `${process.env.REACT_APP_DNS}/api/

//intercepta requisi�oes da api
api.interceptors.request.use(async (config) => {
  const TOKEN = await window.localStorage.getItem("Token");

  try {
    config.headers.Authorization = `Bearer ${TOKEN}`;

    return config;
  } catch (err) {
    console.log(err);
  }
});

// api.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (401 === error.response.status) {
//       cookie.remove("authed", { path: "/" });
//       cookie.remove("locale", { path: "/" });
//       cookie.remove("cliente", { path: "/" });
//       cookie.remove("moeda", { path: "/" });
//       cookie.remove("role", { path: "/" });
//       localStorage.clear();

//       window.location = "/";
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );

//retorna query string (parametro na baseUrl)
api.interceptors.request.use((config) => {
  // console.log('aqui');
  // console.log(config);
  const objEmailFiltro = localStorage.getItem("dscEmailUsuario") ? { dscemail: localStorage.getItem("dscEmailUsuario") } : {};

  config.params = {
    moeda: localStorage.getItem("Moeda"),
    locale: localStorage.getItem("Locale"),
    projeto: localStorage.getItem("Projeto"),
    anoref: localStorage.getItem("Ano"),
    mesref: localStorage.getItem("Mes"),
    bu: localStorage.getItem("Bu"),
    codvertical: localStorage.getItem("Vertical"),
    codpais: localStorage.getItem("Pais"),
    ...(objEmailFiltro ?? {}),
    ...config.params
  };

  return config;
});

export default api;
