import React from "react";
import { Card, Row } from "react-bootstrap";
import { TitleHeader, BadgeShimmer, TextLoading } from "./style";

export default function Loading() {
  return (
    <div className="d-flex">
      <BadgeShimmer className="mr-2" />
      <BadgeShimmer className="mr-2" />
      <BadgeShimmer className="mr-2" />
      <BadgeShimmer className="mr-2" />
      <BadgeShimmer className="mr-2" />
      <BadgeShimmer />
    </div>
  );
}
