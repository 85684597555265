import React from 'react';
import { Row } from 'react-bootstrap';

//templates
import Panel from "../../templates/Panel";
import Spin from "../../components/Spin";

const LoadingSpin = () => {
    return(
        localStorage.getItem("Token") ?
        <Panel>    
            <Row md={12} lg={12} xl={12} style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", height: 300}}>
                <Spin />
            </Row>
        </Panel>
        :
        <></>
    )
}

export default LoadingSpin;