import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

//utils
import { parseJwt } from "btb-shared-fe";
import cookie from "react-cookies";

//LoadingSpin
import LoadingSpin from "./pages/LoadingSpin";

const Login = lazy(() => import("./templates/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Faturamento = lazy(() => import("./pages/Telecom/Faturamento"));
const Protocolos = lazy(() => import("./pages/Telecom/Protocolos"));
const Contas = lazy(() => import("./pages/Telecom/Contas"));
const Tickets = lazy(() => import("./pages/Tickets"));
const TicketsCreate = lazy(() => import("./pages/Tickets/TicketsCreate"));
const TicketsDetails = lazy(() => import("./pages/Tickets/TicketsDetails"));
const AcoesSimularAcesso = lazy(() => import("./pages/Acoes/SimularAcesso"));
const DashboardInventario = lazy(() =>
  import("./pages/Dashboard/DashboardInventario")
);
const DetalhesFaturamento = lazy(() =>
  import("./pages/Telecom/Faturamento/DetalhesFaturamento")
);
const ClasseServico = lazy(() =>
  import("./pages/Telecom/Relatorios/ClasseServico")
);
const ConsolidadoConsumo = lazy(() =>
  import("./pages/Telecom/Relatorios/ConsolidadoConsumo")
);
const ConsolidadoPerfil = lazy(() =>
  import("./pages/Telecom/Relatorios/ConsolidadoPerfil")
);
const ConsolidadoCentroCusto = lazy(() =>
  import("./pages/Telecom/Relatorios/ConsolidoCentroCusto")
);
//Inventario
const ConsolidadoStatus = lazy(() =>
  import("./pages/Inventario/ConsolidadoPorStatus")
);
const RefreshDispositivos = lazy(() =>
  import("./pages/Inventario/RefreshDispositivos")
);
const RefreshConsolidado = lazy(() =>
  import("./pages/Inventario/RefreshConsolidado")
);
// const Inventario = lazy(() => import("./pages/Inventario"));
const InventarioDeLinhas = lazy(() =>
  import("./pages/Inventario/InventarioDeLinhas")
);

// const Ativo = lazy(() => import("./pages/Inventario/Ativo"));
// const DetalhesAtivo = lazy(() => import("./pages/Inventario/Ativo/DetalhesAtivo"));
// const EditarAtivo = lazy(() => import("./pages/Inventario/Ativo/EditarAtivo"));
// const CriarAtivo = lazy(() => import("./pages/Inventario/Ativo/CriarAtivo"));

//inventario
const Ativo = lazy(() => import("./pages/Inventario/Ativo"));
const DetalhesAtivo = lazy(() => import("./pages/Inventario/Ativo/DetalhesAtivo"));
const AtivoCriarEditar = lazy(() => import("./pages/Inventario/Ativo/CriarEditar"));

const TipoAtivo = lazy(() => import("./pages/Inventario/TipoAtivo"));
// const TipoAtivoCriarEditar = lazy(() => import("./pages/Inventario/TipoAtivo/CriarEditar"));
const TipoAtivoCriarEditar = lazy(() => import("./pages/Inventario/TipoAtivo/CriarEditar2"));

//Usuários
const ListaUsuarios = lazy(() => import("./pages/Inventario/Usuarios"));
const CadastroUsuario = lazy(() => import("./pages/Inventario/Usuarios/Cadastro"));
const DetalhesUsuario = lazy(() => import("./pages/Inventario/Usuarios/Detalhes"));

const RelacionamentosUsuario = lazy(() =>
  import("./pages/Inventario/GerenciadorDeUsuarios/RelacionamentoUserAtivo")
);
const RelacionamentosAtivo = lazy(() =>
  import("./pages/Inventario/RelacionamentosAtivoAtivo")
);
const GerenciadorDeUsuarios = lazy(() =>
  import("./pages/Inventario/GerenciadorDeUsuarios")
);
const CadastrarUsuario = lazy(() =>
  import("./pages/Inventario/GerenciadorDeUsuarios/CadastrarUsuario")
);
//analise
const Analyze = lazy(() => import("./pages/Analyze"));

//relatorios
const Relatorios = lazy(() => import("./pages/Relatorios"));
const NovoRelatorio = lazy(() => import("./pages/Relatorios/CriarEditar"));
const EditarRelatorio = lazy(() => import("./pages/Relatorios/CriarEditar"));
const DetalhesRelatorio = lazy(() =>
  import("./pages/Relatorios/DetalhesRelatorio")
);

// dashboard relatórios de ativos
const DashRelatorios = lazy(() =>
  import("./pages/Dashboard/DashboardRelatoriosAtivos")
);
const DetalhesDashRelatorios = lazy(() =>
  import("./pages/Dashboard/DashboardRelatoriosAtivos/Detalhes")
);

// dashboard controle de contas
const DashControleContas = lazy(() => import("./pages/Dashboard/ControleContas"));

const ListDevices = lazy(() => import('./pages/Store/ListDevices'));
const Device = lazy(() => import('./pages/Store/Device'));
const DeviceSteps = lazy(() => import('./pages/Store/Steps'));
const Tracking = lazy(() => import("./pages/Store/Tracking/Orders"));
const TrackingDetails = lazy(() => import("./pages/Store/Tracking/Details"));
const RegisterDevice = lazy(() => import("./pages/Store/Stock/Form"))
const EditDevice = lazy(() => import("./pages/Store/Stock/EditDevice"))
const PageSelect = lazy(() => import("./pages/Store/PageSelect"))
const DetailsDevicePurchased = lazy(() =>
  import("./pages/Store/Purchased/details")
);
const DetailsDeviceOrders = lazy(() =>
  import("./pages/Store/Orders/Details")
);

const Payments = lazy(() => import("./pages/Store/Payments/Database"))

const RegisterEditPayment = lazy(() => import("./pages/Store/Payments/CreateEdit"))

const AlterarSenha = lazy(() => import("./pages/alterarSenha"));

const Contratos = lazy(() => import("./pages/Financeiro/Contrato"));
const DetalhesContrato = lazy(() => import("./pages/Financeiro/Contrato/DetalhesContrato"));
const ContratoContas = lazy(() => import("./pages/Financeiro/Contrato/Conta"));
const DetalhesConta = lazy(() => import("./pages/Financeiro/Contrato/Conta/DetalhesConta"));
const EditarConta = lazy(() => import("./pages/Financeiro/Contrato/Conta/CriarEditar"));
const Fatura = lazy(() => import("./pages/Financeiro/Contrato/Fatura"));
const DetalhesFatura = lazy(() => import("./pages/Financeiro/Contrato/Fatura/DetalhesFatura"));
// const EditarFatura = lazy(() => import("./pages/Financeiro/Contrato/Fatura/SalvarEditarFatura"));
const CadastroFatura = lazy(() => import("./pages/Financeiro/Contrato/Fatura/CadastroFatura"));

const ConfigsMenu = lazy(() => import("./pages/Configuracoes/Menu"));
const AtivoPermissionamento = lazy(() => import("./pages/Configuracoes/Permissionamentos/Ativo"));
const TipoAtivoPermissionamento = lazy(() => import("./pages/Configuracoes/Permissionamentos/TipoAtivo"));
const FiltroCampoAtivos = lazy(() => import("./pages/Configuracoes/FiltroCampoAtivos"));
const PropsTipoAtivoPermissionamento = lazy(() => import("./pages/Configuracoes/Permissionamentos/PropsTipoAtivo"));
const ExtensoesEstoqueMinimo = lazy(() => import("./pages/Configuracoes/Extensoes/EstoqueMinimo"));

const DetalhamentoAtivoArquivo = lazy(() => import("./pages/Billing/Detalhamento/AtivoDetalheArquivo"));
const DetalhamentoAtivoDetalhe = lazy(() => import("./pages/Billing/Detalhamento/AtivoDetalhe"));

const Superset = lazy(() => import("./pages/Dashboard/Superset"));

export default function App() {

  const isAuthenticated = (objPrivateRoute) => {
    if (!localStorage.getItem("Token"))
      return false;

    // link/rota não possui controle 
    if (!objPrivateRoute?.role)
      return true;

    const objToken = parseJwt(localStorage.getItem("Token"));

    if (objPrivateRoute?.role) {
      const flagContemRole = objPrivateRoute.role.some(e => objToken.role.find(b => b.toUpperCase() === e.toUpperCase()));

      if (flagContemRole)
        return true;
    }

    return false;
  }

  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(routerMeta) =>
          isAuthenticated({ ...rest })
            ? <Component {...routerMeta} />
            : <Redirect to={{ pathname: "/", state: { from: routerMeta.location } }} />
        }
      />
    );
  }

  const populaLocalStorageQuandoSSO = () => {
    const res = cookie.loadAll();

    if (res.authed && !localStorage.getItem("Token")) {
      localStorage.setItem("Token", unescape(res.authed));
      localStorage.setItem("Locale", unescape(res.locale));
      localStorage.setItem("Cliente", unescape(res.cliente));
      localStorage.setItem("Usuario", unescape(res.usuario));
      localStorage.setItem("Moeda", unescape(res.moeda));
      localStorage.setItem("Role", unescape(res.role));

      if (res._object) {
        localStorage.setItem("Object", unescape(res._object));
      }

      cookie.remove("authed", { path: "/" });

      setTimeout(function () {
        window.location.reload();
      }, 100);
    }
  }

  populaLocalStorageQuandoSSO();

  return (
    <>
      <Router>
        <Switch>
          <React.Suspense fallback={<LoadingSpin />}>
            <Route exact path="/">
              <Login />
            </Route>

            {/*Dashboard*/}
            <PrivateRoute
              exact
              path="/dashboard"
              role={null}
              component={() => <Dashboard />}
            />

            <PrivateRoute
              exact
              path="/dashboard/superset"
              role={null}
              component={() => <Superset />}
            />

            <PrivateRoute
              exact
              path="/dashboard/dashboard-telecom"
              role={null}
              component={() => <Dashboard />}
            />

            {/*Dasboard Inventario*/}
            <PrivateRoute
              exact
              path="/dashboard/dashboard-inventario"
              role={["admin", "dashInventario-ro", "dashInventario-rw"]}
              component={() => <DashboardInventario />}
            />

            {/* Dashboard Relatórios */}
            <PrivateRoute
              exact
              path="/dashboard/dashboard-relatorios"
              role={["admin", "dashRelatorioAtivo-ro", "dashRelatorioAtivo-rw"]}
              component={() => <DashRelatorios />}
            />
            <PrivateRoute
              exact
              path="/dashboard/dashboard-relatorios/:codCliente/:codDashboard"
              role={["admin", "dashRelatorioAtivo-ro", "dashRelatorioAtivo-rw"]}
              component={DetalhesDashRelatorios}
            />

            {/* Dashboard Contas */}
            <PrivateRoute
              exact
              path="/dashboard/controlecontas"
              role={["admin", "dashControleContas-ro", "dashControleContas-rw"]}
              component={() => <DashControleContas />}
            />

            {/*Faturamento*/}
            <PrivateRoute
              role={null}
              path="/faturamento"
              component={() => <Faturamento />}
            />
            <PrivateRoute
              role={["admin", "telProtCont-ro", "telProtCont-rw"]}
              path="/protocolos"
              component={() => <Protocolos />}
            />
            <PrivateRoute
              role={["admin", "telMapContas-ro", "telMapContas-rw"]}
              path="/contas"
              component={() => <Contas />}
            />

            <PrivateRoute
              // objectId="Detalhamento"
              role={null} // TODO**
              path="/detalhes-da-fatura/:id"
              component={() => <DetalhesFaturamento />}
            />

            {/*Relatorios*/}
            <PrivateRoute
              path="/consolidado-por-consumo"
              role={null}
              component={() => <ConsolidadoConsumo />}
            />
            <PrivateRoute
              path="/classe-de-servico"
              role={null}
              component={() => <ClasseServico />}
            />
            <PrivateRoute
              path="/consolidado-centro-custo"
              role={null}
              component={() => <ConsolidadoCentroCusto />}
            />
            <PrivateRoute
              path="/consolidado-por-perfil"
              role={null}
              component={() => <ConsolidadoPerfil />}
            />

            {/*Inventario*/}
            <PrivateRoute
              path="/inventario-de-linhas"
              role={["admin", "invInvLinhas-ro", "invInvLinhas-rw"]}
              component={() => <InventarioDeLinhas />}
            />
            <PrivateRoute
              path="/consolidado-por-status"
              role={["admin", "invRelConsStatus-ro", "invRelConsStatus-rw"]}
              component={() => <ConsolidadoStatus />}
            />
            <PrivateRoute
              path="/refresh-consolidado"
              role={["admin", "invRelRefreshCons-ro", "invRelRefreshCons-rw"]}
              component={() => <RefreshConsolidado />}
            />
            <PrivateRoute
              path="/refresh-de-dispositivos"
              role={["admin", "invRefreshDisp-ro", "invRefreshDisp-rw"]}
              component={() => <RefreshDispositivos />}
            />

            {/* ativo */}
            <PrivateRoute
              exact
              role={["admin", "ativo-ro", "ativo-rw"]}
              path="/inventario/ativo"
              component={() => <Ativo />}
            />
            <PrivateRoute
              exact
              role={["admin", "ativo-rw"]}
              path="/inventario/ativo/criar-ativo"
              component={AtivoCriarEditar}
            />
            <PrivateRoute
              exact
              role={["admin", "ativo-ro", "ativo-rw"]}
              path="/inventario/ativo/detalhes-inventario/:id"
              component={DetalhesAtivo}
            />
            <PrivateRoute
              exact
              role={["admin", "ativo-rw"]}
              path="/inventario/ativo/editar-ativo/:codAtivoParam"
              component={AtivoCriarEditar}
            />
            <PrivateRoute
              exact
              role={["admin", "ativoXativo-ro", "ativoXativo-rw"]}
              path="/inventario/ativo/relacionamentos-ativos-x-ativos/:codCliente/:codAtivo"
              component={RelacionamentosAtivo}
            />

            {/* usuários */}
            <PrivateRoute
              exact
              role={["admin", "usuario", "usuario-ro", "usuario-rw"]}
              path="/inventario/usuarios"
              component={ListaUsuarios}
            />

            <PrivateRoute
              exact
              role={["admin", "usuario", "usuario-ro", "usuario-rw"]}
              path="/inventario/usuarios/detalhes-usuario/:codUsuario"
              component={DetalhesUsuario}
            />

            <PrivateRoute
              exact
              role={["admin", "usuario-rw"]}
              path="/inventario/usuarios/cadastro-usuario"
              component={CadastroUsuario}
            />

            <PrivateRoute
              exact
              role={["admin", "usuario-rw"]}
              path="/inventario/usuarios/cadastro-usuario/:codUsuario"
              component={CadastroUsuario}
            />

            {/* tipo ativo */}
            <PrivateRoute
              exact
              role={["admin", "tipoAtivo-ro", "tipoAtivo-rw", "ativo-ro", "ativo-rw"]}
              path="/inventario"
              component={() => <TipoAtivo />}
            />
            <PrivateRoute
              exact
              role={["admin", "tipoAtivo-rw"]}
              path="/inventario/tipo-ativo/criar-tipo-ativo"
              component={() => <TipoAtivoCriarEditar />}
            />
            <PrivateRoute
              exact
              role={["admin", "tipoAtivo-rw"]}
              path="/inventario/tipo-ativo/editar-tipo-ativo/:codTipoAtivo"
              component={() => <TipoAtivoCriarEditar />}
            />

            <PrivateRoute
              exact
              // objectId={"Inventario"}
              role={null} // TODO**
              path="/gerenciador-de-usuarios"
              component={() => <GerenciadorDeUsuarios />}
            />
            <PrivateRoute
              exact
              // objectId={"Inventario"}
              role={null} // TODO**
              path="/cadastrar-usuario"
              component={() => <CadastrarUsuario />}
            />
            <PrivateRoute
              exact
              // objectId={"Inventario"}
              role={null} // TODO**
              path="/relacionamentos-usuarios-x-ativo/:codUsuario/:codCliente"
              component={() => <RelacionamentosUsuario />}
            />
            <PrivateRoute
              exact
              role={["admin", "ativoXativo-rw"]}
              path="/relacionamentos-ativos-x-ativos/:codCliente/:codAtivo"
              component={() => <RelacionamentosAtivo />}
            />

            {/**Relatorios */}
            <PrivateRoute
              exact
              role={["admin", "ativoRelatorio-ro", "ativoRelatorio-rw"]}
              path="/relatorios/ativos"
              component={() => <Relatorios />}
            />
            <PrivateRoute
              exact
              role={["admin", "ativoRelatorio-rw"]}
              path="/relatorios/ativos/novo-relatorio"
              component={() => <NovoRelatorio />}
            />
            <PrivateRoute
              exact
              role={["admin", "ativoRelatorio-rw"]}
              path="/relatorios/ativos/editar-relatorio/:codRelatorio"
              component={() => <EditarRelatorio />}
            />
            <PrivateRoute
              exact
              role={["admin", "ativoRelatorio-ro", "ativoRelatorio-rw"]}
              path="/relatorios/ativos/detalhes-relatorio/:id"
              component={() => <DetalhesRelatorio />}
            />

            {/*Tickets*/}
            <PrivateRoute
              role={["admin", "tickets-ro", "tickets-rw"]}
              path="/tickets"
              component={() => <Tickets />}
            />
            <PrivateRoute
              role={["admin", "tickets-rw"]}
              path="/tickets-create"
              component={() => <TicketsCreate />}
            />
            <PrivateRoute
              role={["admin", "tickets-ro", "tickets-rw"]}
              path="/tickets-details/:id"
              component={() => <TicketsDetails />}
            />

            {/* Ações */}
            <PrivateRoute
              role={["admin", "simAcessoGestor-ro", "simAcessoGestor-rw"]}
              path="/actions-simulate"
              component={() => <AcoesSimularAcesso />}
            />

            {/* Store */}
            <Route
              exact
              role={["admin", "store-ro", "store-rw"]}
              path="/store"
              render={() => <ListDevices />} />
            <Route
              exact
              role={["admin", "store-ro", "store-rw"]}
              path="/store/order-tracking"
              component={() => <Tracking />} />
            <Route
              exact
              role={["admin", "store-ro", "store-rw"]}
              path="/store/order-tracking-details/:codPedido"
              component={() => <TrackingDetails />} />

            <Route
              exact
              role={["admin", "stoDevices-ro", "stoDevices-rw"]}
              path="/store/devices"
              component={() => <PageSelect />}
            />
            <Route
              exact
              role={["admin", "stoDevices-ro", "stoDevices-rw"]}
              path="/store/registerdevice"
              component={() => <RegisterDevice />}
            />
            <Route
              exact
              role={["admin", "stoDevices-ro", "stoDevices-rw"]}
              path="/store/devices/details/:codModelo"
              component={() => <EditDevice />}
            />

            <Route
              role={["admin", "stoDevices-ro", "stoDevices-rw"]}
              path="/device/:codMarca/:codAparelho"
              render={() => <Device />}
            />

            <Route
              exact
              role={["admin", "stoDevices-ro", "stoDevices-rw"]}
              path="/store/devices/details-purchased/:codPedido"
              component={() => <DetailsDevicePurchased />}
            />
            <Route
              exact
              role={["admin", "stoDevices-ro", "stoDevices-rw"]}
              path="/store/devices/details-orders/:codDespacho/"
              component={() => <DetailsDeviceOrders />}
            />
            <Route
              exact
              role={["admin", "stoPayments-ro", "stoPayments-rw"]}
              path="/store/payments"
              component={() => <Payments />}
            />
            <Route
              exact
              role={["admin", "stoPayments-rw"]}
              path="/store/payments/registerpayment"
              component={() => <RegisterEditPayment />}
            />

            <Route
              exact
              role={["admin", "stoPayments-rw"]}
              path="/store/payments/edit-payment/:codOpPagamento"
              component={() => <RegisterEditPayment />}
            />

            <Route
              role={["admin", "store-ro", "store-rw"]}
              path="/steps"
              render={() => <DeviceSteps />} />

            {/* Billing */}
            <PrivateRoute
              exact
              role={["admin", "detalhamentoAtivoArquivo-ro", "detalhamentoAtivoArquivo-rw"]}
              path="/billing/detalhamento/ativos/arquivos"
              component={() => <DetalhamentoAtivoArquivo />}
            />

            <PrivateRoute
              exact
              role={["admin", "detalhamentoAtivoDetalhe-ro", "detalhamentoAtivoDetalhe-rw"]}
              path="/billing/detalhamento/ativos/detalhes"
              component={() => <DetalhamentoAtivoDetalhe />}
            />

            <PrivateRoute
              exact
              role={["admin", "detalhamentoAtivoDetalhe-ro", "detalhamentoAtivoDetalhe-rw"]}
              path="/billing/detalhamento/ativos/detalhes/:codAtivoDetalheArquivo"
              component={() => <DetalhamentoAtivoDetalhe />}
            />

            {/* Contratos */}
            <PrivateRoute
              exact
              role={["admin", "contrato-ro", "contrato-rw"]}
              path="/financeiro/contrato"
              component={() => <Contratos />}
            />

            <PrivateRoute
              exact
              role={["admin", "contrato-ro", "contrato-rw"]}
              path="/financeiro/contrato/detalhe-contrato/:id"
              component={() => <DetalhesContrato />}
            />

            <PrivateRoute
              exact
              role={["admin", "finFatura-ro", "finFatura-rw"]}
              path="/financeiro/contrato/fatura"
              component={Fatura}
            />
            <PrivateRoute
              exact
              role={["admin", "finFatura-ro", "finFatura-rw"]}
              path="/financeiro/contrato/fatura/detalhe-fatura/:id"
              component={DetalhesFatura}
            />
            {/* <PrivateRoute
              exact
              role={["admin", "finFatura-ro", "finFatura-rw"]}
              path="/financeiro/contrato/fatura/editar-fatura/:id"
              component={EditarFatura}
            /> */}
            <PrivateRoute
              exact
              role={["admin", "finFatura-ro", "finFatura-rw"]}
              path="/financeiro/contrato/fatura/cadastro-fatura"
              component={CadastroFatura}
            />
            <PrivateRoute
              exact
              role={["admin", "finFatura-ro", "finFatura-rw"]}
              path="/financeiro/contrato/fatura/cadastro-fatura/:id"
              component={CadastroFatura}
            />

            <PrivateRoute
              exact
              role={["admin", "finConta-ro", "finConta-rw"]}
              path="/financeiro/contrato/conta"
              component={ContratoContas}
            />
            <PrivateRoute
              exact
              role={["admin", "finConta-ro", "finConta-rw"]}
              path="/financeiro/contrato/conta/detalhe-conta/:id"
              component={DetalhesConta}
            />
            <PrivateRoute
              exact
              role={["admin", "finConta-rw"]}
              path="/financeiro/contrato/conta/editar-conta/:id"
              component={EditarConta}
            />

            {/**Analyze */}
            <PrivateRoute
              role={null} //TODO**
              path="/analise"
              component={() => <Analyze />}
            />

            <PrivateRoute
              exact
              path="/alterar-senha"
              role={null}
              component={AlterarSenha}
            />

            {/* Configurações */}
            <PrivateRoute
              exact
              path="/configs/menu"
              role={["admin"]}
              component={ConfigsMenu}
            />
            <PrivateRoute
              exact
              path="/configs/ativo/permissionamento"
              role={["admin", "ativosConfig-ro", "ativosConfig-rw", "permAtivos-ro", "permAtivos-rw"]}
              component={AtivoPermissionamento}
            />
            <PrivateRoute
              exact
              path="/configs/ativo/filtro-campo"
              role={["admin", "configsExtrasAtivos-ro", "configsExtrasAtivos-rw", "gerFiltroCampo-ro", "gerFiltroCampo-rw"]}
              component={FiltroCampoAtivos}
            />
            <PrivateRoute
              exact
              path="/configs/tipo-ativo/permissionamento"
              role={["admin", "ativosConfig-ro", "ativosConfig-rw", "permTipoAtivo-ro", "permTipoAtivo-rw"]}
              component={TipoAtivoPermissionamento}
            />
            <PrivateRoute
              exact
              path="/configs/tipo-ativo/props/permissionamento"
              role={["admin", "ativosConfig-ro", "ativosConfig-rw", "propsTipoAtivo-ro", "propsTipoAtivo-rw"]}
              component={PropsTipoAtivoPermissionamento}
            />
            <PrivateRoute
              exact
              path="/configs/extensoes/estoque-minimo"
              role={["admin", "configsExtrasAtivos-ro", "configsExtrasAtivos-rw", "estqMin-ro", "estqMin-rw"]}
              component={ExtensoesEstoqueMinimo}
            />

          </React.Suspense>
        </Switch>
      </Router>
    </>
  );
}
