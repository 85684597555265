import { combineReducers } from "redux";

import viewMode from "./viewMode/reducer.js";
import detalhes from "./detalhesFatura/reducer";
import ciclo from "./ciclo/reducer.js";
import telefonia from "./faturamento/reducer.js";
import inventario from "./inventario/reducer";
import devicesCart from './devicesCart/reducer';
import cliente from "./cliente/clienteReducer";

export default combineReducers({
  viewMode,
  detalhes,
  ciclo,
  telefonia,
  inventario,
  devicesCart,
  cliente
});
