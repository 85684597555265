const INITIAL_STATE = {
  data: {},
};

const inventario = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_INVENTARIO":
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default inventario;
