import cookie from "react-cookies";

const LogOut = (history) => {    
    cookie.remove("authed", { path: "/" });
    cookie.remove("locale", { path: "/" });
    cookie.remove("cliente", { path: "/" });
    cookie.remove("moeda", { path: "/" });
    cookie.remove("role", { path: "/" });

    // contexto simulação de usuário
    const objLocalStorage = localStorage.getItem("ObjLocalStorageOriginal");

    const objTemplate = JSON.parse(localStorage.getItem("ls-objTemplate"));

    const redirectUri = (objTemplate?.siglaCliente ? `/?c=${objTemplate?.siglaCliente}` : '/');

    localStorage.clear();
    sessionStorage.clear();

    if (objLocalStorage) {
        const obj = JSON.parse(objLocalStorage);

        localStorage.setItem("Token", obj["Token"] ? obj["Token"] : null);
        localStorage.setItem("Locale", obj["Locale"] ? obj["Locale"] : null);
        localStorage.setItem("Usuario", obj["Usuario"] ? obj["Usuario"] : null);
        localStorage.setItem("Cliente", obj["Cliente"] ? obj["Cliente"] : null);
        localStorage.setItem("Moeda", obj["Moeda"] ? obj["Moeda"] : null);
        localStorage.setItem("Object", obj["Object"] ? obj["Object"] : null);
        localStorage.setItem("Email", obj["Email"] ? obj["Email"] : null);
        localStorage.setItem("Mes", obj["Mes"] ? obj["Mes"] : null);
        localStorage.setItem("Ano", obj["Ano"] ? obj["Ano"] : null);
        localStorage.setItem("Ciclo", obj["Ciclo"] ? obj["Ciclo"] : null);
        localStorage.setItem("Homepath", obj["Homepath"] ? JSON.stringify(obj["Homepath"]) : null);

        history.push(obj["Homepath"] ?? "/dashboard/dashboard-telecom");
        history.go(0);
    }
    else{
        history.push(redirectUri);
        history.go(0);
    }
}

export { LogOut }; // outras funções dentro deste arquivo devem ser exportadas aqui
export default LogOut;