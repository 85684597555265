import useSWR from "swr";
import api from "../services/api";
import apiNet from "../services/apiNet";
import apiBender from "../services/apiBender";

export function useRequesitionGet(url, callbackConfig) {
  const { data, error } = useSWR(url, async () => {
    if (Array.isArray(url)) {
      return (await api.get(url[0], (callbackConfig) ? callbackConfig(url.slice(1)) : null)).data;
    }
    else
    {
      return (await api.get(url, (callbackConfig) ? callbackConfig() : null)).data;
    }
  });
  return { data, error };
}

export function useRequesitionData(url) {
  const { data, error } = useSWR(url, async (url) => {
    const response = await apiNet.get(url);
    return response.data.data;
  });
  return { data, error };
}

export function useRequesitionGetBender(url, options) {
  const { data, error } = useSWR(url, async (url) => {
    const response = await apiBender.get(url);
    return response.data;
  }, options);
  return { data, error };
}

export function useRequesitionDataPost(url) {
  const { data, error } = useSWR(url, async (url) => {
    const response = await apiNet.post(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  });
  return { data, error };
}
