import React from "react";
import { Col } from "react-bootstrap";

import { DetailsBody } from "./style";

const ElementLoading = () => {
    return (
        <>
            <Col sm={12} className="d-none d-sm-block" style={{paddingLeft: 0, paddingRight: 0}}>
                <DetailsBody className="" />
            </Col>
        </>
    );
}

export default ElementLoading;
