import "./i18nInit";
import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";

import App from "./components/App";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Routes />
      <App windowLocation={window.location} />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
