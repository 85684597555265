import { parseJwt } from 'btb-shared-fe';
import api from '../services/apiBender';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { formatDataReduced } from "../utils/dataFormat";
import { toast } from "react-toastify";

// a prop 'data' pode receber tanto uma string simples qnt um valor 'base64'
const efetivaDownload = (data, name, dataType) => {
    // string to array buffer
    const s2ab = (strData) => {
        var buf = new ArrayBuffer(strData.length);
        var view = new Uint8Array(buf);

        for (var i = 0; i !== strData.length; ++i)
            view[i] = strData.charCodeAt(i) & 0xFF;

        return buf;
    }

    const base64Regex = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
    const bf = ((dataType && dataType === "base64") || base64Regex.test(data))
        ? Buffer.from(data, 'base64')
        : s2ab(data);

    const blob = new Blob(["", bf]);
    var url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");

    anchor.setAttribute("href", url);
    anchor.setAttribute("download", name);

    anchor.dispatchEvent(new MouseEvent("click", { bubbles: true, cancelable: true, view: window }));
    window.URL.revokeObjectURL(url);
}

const getCodTipoAtivo = (objRelatorio) => {
    let codTipoAtivoAux = "TODOS_OS_ATIVOS";

    if (objRelatorio?.dscFiltros) {
        const objFiltros = JSON.parse(objRelatorio.dscFiltros);
        const result = objFiltros.find(e => e.campo === "codTipoAtivo")?.valor;

        if (result)
            codTipoAtivoAux = result;
    }

    return codTipoAtivoAux;
}

const carregaIframe = (history) => {
    return window.onmessage = function (e) {
        if (e.origin !== `${process.env.REACT_APP_BTBM_LEGADO_URL}` && e.origin !== `${process.env.REACT_APP_BTBM_LEGADO_URL}.br` && e.origin !== 'http://localhost:63396')
            return;

        const payload = JSON.parse(e.data);
        const objToken = parseJwt(payload["data"]);

        // localStorage.setItem("User", *TODO?*);   
        // localStorage.setItem("Data", *TODO?*);            
        localStorage.setItem("Token", payload["data"]);
        localStorage.setItem("Email", objToken["unique_name"]);

        if (history)
            history.go(0);
    };
}

const obtemPropSemId = (propName) => {
    if (propName.match(/[_]\w{4}$/))
        return propName.substr(0, propName.length - 5);

    return propName;
}

const validaTipoAtivoCustomizado = (objDDLTipoAtivo) => {
    if (objDDLTipoAtivo) {
        const nomTipoATivo = { ...objDDLTipoAtivo }.label;

        if (nomTipoATivo === "ESTOQUE" || nomTipoATivo === "ESTOQUE BTB" || nomTipoATivo === "LINHA")
            return true;
    }

    return false;
}

const removePropsVazias = (formData) => {
    const formDataAux = {};

    if (formData) {
        Object.entries(formData).forEach(el => {
            if (el[1] !== "" && el[1] !== null && el[1] !== undefined && !(Array.isArray(el[1]) && el[1].length === 0)) {
                formDataAux[el[0]] = el[1];
            }
        });

        return formDataAux;
    }

    return formData;
};

const constroiObjetoArquivo = (event, codCliente, codTipoAtivo, setListaAnexosInsercao) => {
    if (event.target.files.length > 0) {
        const fileList = event.target.files;
        const listaAux = [];

        Object.values(fileList).forEach(file => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onloadend = function () {
                let result = reader.result;

                const aux = "base64,";
                result = result.substr(result.indexOf(aux) + aux.length, result.length);

                const newObject = {
                    fileOriginalName: file.name,
                    contentBytes: result,
                    size: file.size,
                };

                if (codCliente)
                    newObject["codCliente"] = codCliente;

                if (codTipoAtivo)
                    newObject["codTipoAtivo"] = codTipoAtivo;

                listaAux.push(newObject);
                setListaAnexosInsercao(listaAux);
            };
        });
    }
};

const constroiObjetoArquivoContratos = (event, codCliente, codTipoAtivo, setListaAnexosInsercao) => {
    if (event.target.files.length > 0) {
            const fileList = event.target.files;
            const listaAux = [];
    
            Object.values(fileList).forEach(file => {
                const newObject = {
                    fileOriginalName: file.name,
                    file: file,
                    size: file.size,
                };
    
                if (codCliente)
                    newObject["codCliente"] = codCliente;
    
                if (codTipoAtivo)
                    newObject["codTipoAtivo"] = codTipoAtivo;
    
                listaAux.push(newObject);
                setListaAnexosInsercao(listaAux);
            });
    }
};

const obtemRolesWritePorModulo = (nomModulo) => {
    switch (nomModulo && nomModulo.toUpperCase()) {
        case "ATIVO":
            return ["admin", "ativo-rw"]
        case "TIPOATIVO":
            return ["admin", "tipoAtivo-rw"]
        case "ATIVORELATORIO":
            return ["admin", "ativoRelatorio-rw"]
        case "CONTRATO":
            return ["admin", "contrato-rw"]
        case "DASH-ATIVOS":
            return ["admin", "dashRelatorioAtivo-rw"]
        case "GER-PERM":
            return ["admin", "ativosConfig-rw", "permAtivos-rw", "permTipoAtivo-rw", "propsTipoAtivo-rw"]
        case "CONFIG-ADIC":
            return ["admin", "configsExtrasAtivos-rw", "gerFiltroCampo-rw", "estqMin-rw"]
        case "PERMATIVOS":
            return ["admin", "ativosConfig-rw", "permAtivos-rw",]
        case "PERMTIPOATIVO":
            return ["admin", "ativosConfig-rw","permTipoAtivo-rw"]            
        case "PROPSTIPOATIVO":
            return ["admin", "ativosConfig-rw", "propsTipoAtivo-rw"]
        case "GERFILTROCAMPO":
            return ["admin", "configsExtrasAtivos-rw", "gerFiltroCampo-rw"]
        case "ESTQMIN":
            return ["admin", "configsExtrasAtivos-rw", "estqMin-rw"]
        default:
            return ["admin", "usuario", "usuario-rw"];
    }
};

const obtemRolesGerais = (nomModulo) => {
    
    switch (nomModulo && nomModulo.toUpperCase()) {
        case "GER-PERM":
            return ["admin", "ativosConfig-ro", "ativosConfig-rw", "permAtivos-rw", "permAtivos-ro", "permTipoAtivo-rw", "permTipoAtivo-ro","propsTipoAtivo-rw", "propsTipoAtivo-ro"]
        case "CONFIG-ADIC":
            return ["admin", "configsExtrasAtivos-ro", "configsExtrasAtivos-rw", "gerFiltroCampo-ro", "gerFiltroCampo-rw", "estqMin-ro", "estqMin-rw"]
        case "PERMATIVOS":
            return ["admin", "ativosConfig-rw", "ativosConfig-ro", "permAtivos-rw", "permAtivos-ro"]
        case "PERMTIPOATIVO":
            return ["admin", "ativosConfig-rw", "ativosConfig-ro", "permTipoAtivo-rw", "permTipoAtivo-ro"]            
        case "PROPSTIPOATIVO":
            return ["admin", "ativosConfig-rw", "ativosConfig-ro", "propsTipoAtivo-rw", "propsTipoAtivo-ro"]
        case "GERFILTROCAMPO":
            return ["admin", "configsExtrasAtivos-ro", "configsExtrasAtivos-rw","gerFiltroCampo-ro", "gerFiltroCampo-rw"]
        case "ESTQMIN":
            return ["admin", "configsExtrasAtivos-ro", "configsExtrasAtivos-rw","estqMin-ro", "estqMin-rw"]
        default:
            return ["admin", "usuario", "usuario-rw"];
    }
}

const validaPermissaoAcesso = (nomModulo, roleType) => {
    const token = localStorage.getItem("Token");
    const objToken = parseJwt(token);
    
    if (objToken && nomModulo) {
        const roles = objToken?.role ?? [];
        if(roleType === "write"){
            return obtemRolesWritePorModulo(nomModulo.toUpperCase()).some(e => roles.some(r => e.includes(r)));
        }
        if(roleType === "gerais"){
            return obtemRolesGerais(nomModulo.toUpperCase()).some(e => roles.some(r => e.includes(r)));
        }
    }

    return false;
}

const convertArrayOfObjectsToCSV = (data) => {
    const csvRows = [];
    const headers = Object.keys(data[0]);

    csvRows.push(headers.join(';'));

    for (const row of data) {
        const values = headers.map(
            header => {
                const val = row[header] ?? "";
                return `${val}`;
            });

        csvRows.push(values.join(';'));
    }

    return csvRows.join('\n');
}

const obtemPropGrid = (propTitle) => {
    const objTipoAtivo = JSON.parse(sessionStorage.getItem("ss-objTA"));

    if (objTipoAtivo && propTitle) {
        const metaPropsSchema = objTipoAtivo?.dscJsonSchema
            ? Object.entries(JSON.parse(objTipoAtivo?.dscJsonSchema)?.properties).map(e => ({ propKey: e[0], propTitle: e[1].title }))
            : [];

        return metaPropsSchema.find(e => e.propTitle === propTitle)?.propKey;
    }
}

// const obtemJsonDocFiltragem = (codCliente, codTipoAtivo) => {
//     if (codCliente && codTipoAtivo) {
//         const objTipoAtivo = JSON.parse(sessionStorage.getItem("ss-objTA"));

//         // obtém os parametros de filtragem
//         const objParamFiltros = JSON.parse(sessionStorage.getItem("ss-formData"));
//         const objParamFiltrosHeader = JSON.parse(sessionStorage.getItem("ss-elFil"));
//         const metaPropsSchema = objTipoAtivo?.dscJsonSchema
//             ? Object.entries(JSON.parse(objTipoAtivo?.dscJsonSchema)?.properties).map(e => ({ propKey: e[0], propTitle: e[1].title }))
//             : [];

//         let jsonDocAux = {};

//         // caso tenha preenchido form em 'Filtros'
//         if (objParamFiltros) {
//             Object.keys(objParamFiltros).forEach(key => {
//                 if (objParamFiltros[key])
//                     jsonDocAux[key] = objParamFiltros[key];
//             });
//         }

//         // caso tenha preenchido qlqer filtro no cabeçalho
//         if (objParamFiltrosHeader) {
//             Object.keys(objParamFiltrosHeader).forEach(title => {
//                 const obj = metaPropsSchema.find(e => e.propTitle === title);

//                 if (obj) {
//                     jsonDocAux[obj.propKey] = objParamFiltrosHeader[obj.propTitle];
//                 }
//             });
//         }

//         // ajusta dados (title -> enum[0]) quando key for um dropdown
//         const objPropsSchema = objTipoAtivo?.dscJsonSchema
//             ? JSON.parse(objTipoAtivo?.dscJsonSchema)?.properties
//             : null;

//         const listaPropsDdl = [];
//         if (objPropsSchema) {
//             Object.keys(objPropsSchema).forEach(key => {
//                 if (objPropsSchema[key] && objPropsSchema[key]?.anyOf)
//                     listaPropsDdl.push(key);
//             });
//         }

//         listaPropsDdl.forEach(key => {
//             if (jsonDocAux[key]) {
//                 jsonDocAux[key] = JSON.parse(objTipoAtivo.dscJsonSchema)
//                     .properties[key].anyOf
//                     .find(e => (e?.title && jsonDocAux[key]) && ("" + e.title)?.toUpperCase() === ("" + jsonDocAux[key])?.toUpperCase())
//                     ?.enum[0] ?? jsonDocAux[key];
//             }
//         });

//         jsonDocAux = removePropsVazias(jsonDocAux);
//         return jsonDocAux;
//     }
// }

const compoeObjFiltragem = (filters, objTipoAtivo) => {
    // todos os metadados envolvidos
    const metaPropsSchema = objTipoAtivo?.dscJsonSchema
        ? Object.entries(JSON.parse(objTipoAtivo?.dscJsonSchema)?.properties)
            .map(e => ({
                propKey: e[0],
                propTitle: e[1]?.title,
                flagDDL: e[1]?.anyOf && e[1]?.anyOf?.length > 0 ? true : false,
                listaResult: e[1]?.anyOf,
                dataType: e[1]?.type ?? "string"
            }))
        : [];

    // adiciona meta p/ props "fixas" (em comum p/ qlqer tipo de ativo)
    metaPropsSchema.push.apply(metaPropsSchema, [
        {
            propKey: "codAtivo",
            propTitle: "Código do ativo",
            flagDDL: false,
            listaResult: undefined,
            dataType: "integer"
        },
        {
            propKey: "nomAtivo",
            propTitle: "Nome do ativo",
            flagDDL: false,
            listaResult: undefined,
            dataType: "string"
        },
        {
            propKey: "dscAtivo",
            propTitle: "Desc do ativo",
            flagDDL: false,
            listaResult: undefined,
            dataType: "string"
        },
        {
            propKey: "dscImpacto",
            propTitle: "Impacto",
            flagDDL: false,
            listaResult: undefined,
            dataType: "string"
        }
    ]);

    // constroi o objeto de filtro de acordo com valores informados x metadados (conteúdo de DDLs)
    const objFilter = {};
    if (filters && filters.length > 0) {
        filters.forEach(el => {
            const obj = metaPropsSchema.find(e => e.propTitle === el.column);

            if (obj) {
                // 240131-DIKE: mudança no comportamento da filtragem
                // no endpoint v2/ativo a busca é feita direto pela descrição do campo (quando na proc @ResolveLabelID = 1)
                // então aqui por enquanto não será mais tratado buscando o código do combo até que seja feito um novo refactor da tela de ativos
                if (obj?.flagDDL) {
                    // objFilter[obj.propKey] = obj.listaResult.find(e => ("" + e?.title).toLocaleLowerCase() === ("" + el?.value).toLocaleLowerCase())?.enum[0] ?? null;
                    objFilter[obj.propKey] = el.value;
                }
                else if (obj.dataType === "number") {
                    objFilter[obj.propKey] = el.value ? Number(el.value) : null;
                }
                else if (obj.dataType === "integer") {
                    objFilter[obj.propKey] = el.value ? parseInt(el.value) : null;
                }
                else if (obj.dataType === "boolean") {
                    objFilter[obj.propKey] = el.value ? ("" + el.value).toLocaleLowerCase() === "true" : null;
                }
                else {
                    objFilter[obj.propKey] = el.value;
                }

                if (!objFilter[obj.propKey])
                    delete objFilter[obj.propKey];
            }
        });
    }

    return objFilter;
}

const obtemListaAtivosParaDownload = async (codCliente, codTipoAtivo, filters) => {
    if (codCliente && codTipoAtivo) {
        const fAux = { ...filters };
        const fAux2 = removePropsFixas({ ...filters });

        // efetiva a coleta dos dados
        const { data: listaAtivos } = await api.get('v2/ativo', {
            params: {
                codAtivo: fAux?.codAtivo,
                nomAtivo: fAux?.nomAtivo,
                dscAtivo: fAux?.dscAtivo,
                dscImpacto: fAux?.dscImpacto,
                codCliente: codCliente,
                codTipoAtivo: codTipoAtivo,
                numPagina: -1,
                tamanhoPagina: -1,
                flagDadosPivotados: true,
                dscJsonDoc: Object.keys(fAux2).length > 0 ? JSON.stringify(fAux2) : null
            }
        });

        listaAtivos.forEach(ativo => {
            if (ativo?.ListaAnexos && ativo?.ListaAnexos.length > 0)
                ativo.ListaAnexos = ativo?.ListaAnexos.map(e => e.fileOriginalName).join(", ");
        });

        return listaAtivos;
    }
}

const exportToXLSX = async (data, fileName) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const res = data.map(({ id, _gauge, _link, ...rest }) => rest);
    const ws = XLSX.utils.json_to_sheet(res);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blobData = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(blobData, fileName + fileExtension);
};

const obtemRotaAcoesAtivo = (objAtivoColumn, nomAcao) => {
    let rota = `/inventario/ativo/${nomAcao}`;
    let id = null;

    if (objAtivoColumn) {
        switch (true) {
            case !objAtivoColumn[getKeyIgnoreCase(objAtivoColumn, "codAtivo")] === false:
                id = objAtivoColumn[getKeyIgnoreCase(objAtivoColumn, "codAtivo")];
                break;

            case !objAtivoColumn[getKeyIgnoreCase(objAtivoColumn, "Código do ativo")] === false:
                id = objAtivoColumn[getKeyIgnoreCase(objAtivoColumn, "Código do ativo")];
                break;

            case !objAtivoColumn[getKeyIgnoreCase(objAtivoColumn, "Código do telefone")] === false:
                id = "t_" + objAtivoColumn[getKeyIgnoreCase(objAtivoColumn, "Código do telefone")];
                break;

            case !objAtivoColumn[getKeyIgnoreCase(objAtivoColumn, "codTelefone")] === false:
                id = "t_" + objAtivoColumn[getKeyIgnoreCase(objAtivoColumn, "codTelefone")];
                break;

            case !objAtivoColumn[getKeyIgnoreCase(objAtivoColumn, "codClienteEstoque")] === false:
                id = "ec_" + objAtivoColumn[getKeyIgnoreCase(objAtivoColumn, "codClienteEstoque")];
                break;

            case !(objAtivoColumn[getKeyIgnoreCase(objAtivoColumn, "Código")] && objAtivoColumn[getKeyIgnoreCase(objAtivoColumn, "Tipo de ativo")] === "ESTOQUE BTB") === false:
                id = "e_" + objAtivoColumn[getKeyIgnoreCase(objAtivoColumn, "Código")];
                break;
            
            case !(objAtivoColumn[getKeyIgnoreCase(objAtivoColumn, "Código")] && objAtivoColumn[getKeyIgnoreCase(objAtivoColumn, "Tipo de ativo")] === "ESTOQUE") === false:
                id = "ec_" + objAtivoColumn[getKeyIgnoreCase(objAtivoColumn, "Código")];
                break;

            case !(objAtivoColumn[getKeyIgnoreCase(objAtivoColumn, "Código")]) === false:
                const codTipoAtivo = objAtivoColumn[getKeyIgnoreCase(objAtivoColumn, "Código do tipo de ativo")];
                
                if(codTipoAtivo && codTipoAtivo.match(/^EST_\d{4}$/)) {
                    id = "ec_" + objAtivoColumn[getKeyIgnoreCase(objAtivoColumn, "Código")];
                }
                else if(codTipoAtivo && codTipoAtivo === "ESTQ_BTB") {
                    id = "e_" + objAtivoColumn[getKeyIgnoreCase(objAtivoColumn, "Código")];
                }
                break;
                
            default:
                break;
        }
    }

    return `${rota}/${id}`;
};

const getKeyIgnoreCase = (obj, key) => {
    if(obj && Object.keys(obj).length > 0 && key) {
        return Object.keys(obj).find(e => e.toLowerCase() === key.toLowerCase());
    }

    return key;
}

const getErrorMessage = (error) => {
    let errTitle = "";
    let errDetails = "";

    if (error && error?.response?.status === 400) {
        errTitle = error?.response?.data?.title;
        errDetails = error?.response?.data?.details 
            ? error?.response?.data?.details.map(e => e.message).join(", ") 
            : error?.response?.data?.errors 
                ? JSON.stringify(error?.response?.data?.errors) 
                : null;        
    }
    else {
        errTitle = error?.message;
        errDetails = error?.response?.data?.title ?? error?.response?.statusText;
    }

    console.log("error", error);
    return errTitle + ": " + errDetails;
}

const obtemNumAnoMesVenctoFormatado = () => {
    const ano = localStorage.getItem("Ano");
    const mes = localStorage.getItem("Mes");
    const data = localStorage.getItem("Data");

    if (ano && mes) {
        return "".concat(ano, mes);
    }
    else if (data) {
        return formatDataReduced(data)
            .split("/")
            .reverse()
            .join("")
    }
}

const obtemListaMetaAuxBackOffice = (meta, nomBackOffice) => {
    let listaCamposAux = [];

    if (meta) {
        const metaFields = meta.find(e => e.nomBackOffice === nomBackOffice);

        // propriedades em comum p/ qlqer ação de backoffice
        listaCamposAux = [
            { nomBackOfficeFluxoCampo: "Data da Operação", dscTipoCampo: "date", numOrdem: 0.1, flagObrigatorio: true },
            { nomBackOfficeFluxoCampo: "Protocolo Op", dscTipoCampo: "string", numOrdem: 0.2, flagObrigatorio: false },
            { nomBackOfficeFluxoCampo: "Número do Chamado", dscTipoCampo: "string", numOrdem: 0.3, flagObrigatorio: false },
            { nomBackOfficeFluxoCampo: "Descrição do Chamado", dscTipoCampo: "string", numOrdem: 0.4, flagObrigatorio: false }
        ];

        const listaCamposResponse = metaFields?.listaFluxos[0]?.listaCampos;
        listaCamposResponse.forEach(e => { listaCamposAux.push(e); });

        // propriedade em comum p/ qlqer ação de backoffice
        listaCamposAux.push({ nomBackOfficeFluxoCampo: "Observação", dscTipoCampo: "string", numOrdem: listaCamposAux.length, flagObrigatorio: false });
    }

    return listaCamposAux;
}

const obtemListaColunasDinamicasRelatorio = (resultSetRelatorio) => {
    let result = null;

    if (resultSetRelatorio) {
        const { listaResultado } = resultSetRelatorio;

        if (listaResultado && listaResultado.length > 0) {
            let listaKeys = [];
            listaResultado.forEach(obj => {
                listaKeys.push.apply(listaKeys, Object.keys(obj))
            });

            listaKeys = [...new Set(listaKeys.map(e => e))];

            const columnsRelatorioAux = listaKeys.map(e => ({
                text: e,
                dataField: e,
                sort: true,
                hidden: false,
            }));

            result = columnsRelatorioAux;
        }
    }

    return result;
}

const obtemListaMetaPropsGraficos = async (objRelatorio) => {
    try {
        const { data: result } = await api.get("v1/relatorio/props", {
            params: {
                codCliente: objRelatorio?.codCliente,
                codTipoAtivo: getCodTipoAtivo(objRelatorio)
            }
        });

        if (result) {
            let nomFonteDadosAux = JSON.parse(objRelatorio.dscFiltros).find(e => e.campo === "nomFonteDados")?.valor;

            if (!nomFonteDadosAux)
                nomFonteDadosAux = "ATIVO";

            if (nomFonteDadosAux === "ATIVO") {
                const listaPropsARemover = ["anoRef", "dtaDetalhe", "duracao", "mesRef", "nomIdentificacao", "nomArquivo", "dscOrigem", "listaTag", "valorAuditado", "valorConta", "valorFaturado"];
                let elementosAux = result?.elementos;
                let listaPropriedadesAux = result?.listaPropriedades;

                listaPropriedadesAux = listaPropriedadesAux.filter(e => !listaPropsARemover.includes(e.value));
                listaPropsARemover.forEach(prop => { delete elementosAux[prop]; });

                result.listaPropriedades = listaPropriedadesAux;
                result.elementos = elementosAux;
            }

            return result;
        }
    } catch (error) {
        console.log("error: ", error);
    }
}

const processaSubmitRelatorio = async (resultFiltros, objRelatorio, pageState) => {
    const { filterResult, dynamicFilter } = resultFiltros;

    try {
        const obj = {
            codCliente: objRelatorio?.codCliente,
            nomRelatorio: objRelatorio?.nomRelatorio,
            dscRelatorio: objRelatorio?.dscRelatorio,
            flagResumo: objRelatorio?.flagResumo,
            dscIconCss: objRelatorio?.dscIconCss,
            flagSomenteLeitura: objRelatorio?.flagSomenteLeitura,
            dscFiltros: pageState === "CRIAÇÃO" ? filterResult : JSON.stringify(filterResult),
            dscDynamicFilter: JSON.stringify(dynamicFilter)
        }

        let responseData = null;

        switch (pageState) {
            case "CRIAÇÃO":
                {
                    const { data: result } = await api.post(`v1/relatorio`, obj);

                    if (result) {
                        responseData = result;
                        toast.success("Relatório criado com sucesso!");
                    }

                    break;
                }
            case "EDIÇÃO":
                {
                    const { data: result } = await api.put(`v1/relatorio/${objRelatorio?.codRelatorio}`, obj);

                    if (result) {
                        responseData = result;
                        toast.success("Relatório atualizado com sucesso!");
                    }
                    break;
                }
            default:
                break;
        }

        return responseData;
    } catch (error) {
        toast.error("Erro: Tente novamente!");
        console.log("error", error);
    }
};

const preparaDadosExportacao = async (csvData, columns = [], filterValueAux) => {
    const obtemObjComNovoCabecalho = (obj) => {
        let result = Object.keys(obj).map(key => ({
            [columns.find(c => c?.dataField === key)?.text ?? null]: obj[key] ?? null
        }));

        // remove 'nulls' [não estão presentes em meta 'columns', por hora descartados]
        result = result.filter(e => Object.keys(e)[0] !== 'null');
        result = Object.assign({}, ...result);

        return result;
    }

    let dataAux = [...csvData];

    if (filterValueAux) {
        filterValueAux.filter(e => !e.value === false).forEach(el => {
            dataAux = dataAux.filter(b => ("" + b[el.column]).includes("" + el.value));
        });
    }

    // renomeia o cabeçalho com "nomes amigáveis" ('text' de 'columns')
    dataAux = dataAux.map(obj => obtemObjComNovoCabecalho(obj));
    return dataAux;
};

const removePropsFixas = (filtro) => {
    const aux = { ...filtro };
    const entriesFiltro = Object.entries(aux);

    for (let i = 0; i < entriesFiltro.length; i++) {
        if (["codAtivo", "nomAtivo", "dscAtivo", "dscImpacto"].some(key => key === entriesFiltro[i][0]))
            delete aux[entriesFiltro[i][0]];
    }

    return aux;
}

export {
    efetivaDownload,
    getCodTipoAtivo,
    carregaIframe,
    obtemPropSemId,
    validaTipoAtivoCustomizado,
    removePropsVazias,
    constroiObjetoArquivo,
    constroiObjetoArquivoContratos,
    obtemRolesWritePorModulo,
    convertArrayOfObjectsToCSV,
    obtemListaAtivosParaDownload,
    exportToXLSX,
    obtemRotaAcoesAtivo,
    getErrorMessage,
    obtemNumAnoMesVenctoFormatado,
    // obtemJsonDocFiltragem,
    obtemPropGrid,
    obtemListaMetaAuxBackOffice,
    obtemListaColunasDinamicasRelatorio,
    obtemListaMetaPropsGraficos,
    processaSubmitRelatorio,
    preparaDadosExportacao,
    compoeObjFiltragem,
    removePropsFixas,
    obtemRolesGerais,
    validaPermissaoAcesso
}
