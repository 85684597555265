import React from 'react';

import './spin.css';

const Spin = () => {
    return(
        <div className="dot-spin"></div>
    )
}

export default Spin;