import React, { useState, useEffect, Fragment } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Navbar,
  Nav,
  Dropdown,
  Button,
  Form,
  Col,
  Badge,
  Modal,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import layoutHelpers from "../../shared/layouts/helpers";
import { c, t } from "ttag";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { parseJwt } from "btb-shared-fe";
import { setSelectedCliente } from "../../store/actions/cliente/clienteAction";

//component
import LoadingBadge from "../Loading/LoadingBadge";

//services
import { useRequesitionGet } from "../../hooks/useRequesition";
import api from "../../services/api";
import { LogOut } from "../../shared/shared-funcs.js";

//utils
import { Meses } from "../../utils/meses";

//imagens
import Logo from "../../assets/img/btb-logo.png";
import useRefreshToken from "../../hooks/useRefreshToken.js";

export default function SideBar(props) {
  // props {
  // sidenavToggle
  // onChangeConfig
  // }
  let history = useHistory();
  const dispatch = useDispatch();
  useRefreshToken();

  //config inicial da aplicação
  const getLocalConfig = () => ({
    pais: localStorage.getItem("Pais"),
    cliente: localStorage.getItem("Cliente"),
    usuario: localStorage.getItem("Usuario"),
    moeda: localStorage.getItem("Moeda"),
    locale: localStorage.getItem("Locale"),
    mes: localStorage.getItem("Mes"),
    ano: localStorage.getItem("Ano"),
    ciclo: localStorage.getItem("Ciclo"),
    projeto: localStorage.getItem("Projeto"),
    bu: localStorage.getItem("Bu"),
    vertical: localStorage.getItem("Vertical"),
    dscEmailUsuario: localStorage.getItem("dscEmailUsuario") 
  });

  let currConfig = getLocalConfig();

  //state
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [selectIdioma, setSelectIdioma] = useState(currConfig?.locale);

  const [selectPais, setSelectPais] = useState(() => currConfig?.pais || "GLOBAL");
  const [selectProjeto, setSelectProjeto] = useState(() => currConfig?.projeto || "GLOBAL");
  const [selectBusiness, setSelectBusiness] = useState(() => currConfig?.bu || "GLOBAL");
  const [selectVertical, setSelectVertical] = useState(() => currConfig?.vertical || "GLOBAL");
  const [selectMoeda, setSelectMoeda] = useState(currConfig?.moeda);
  const [selectCiclo, setSelectCiclo] = useState(currConfig?.ciclo);
  const [flagContemCiclo, setFlagContemCiclo] = useState(false);
  const [selectedCliente, setSelectedClienteLocal] = useState(JSON.parse(localStorage.getItem("selectedCliente")));
  const [dscEmailFiltro, setDscEmailFiltro] = useState(currConfig?.dscEmailUsuario || "");
  const [listaVertical, setListaVertical] = useState();
  const [flagMostrarFiltroEmail, setFlagMostrarFiltroEmail] = useState(false);

  const propControleReferencia = useSelector((state) => state.viewMode.propControleReferencia);

  const [logoSrc, setLogoSrc] = useState();

  //state get
  const blankFilter = { pais: null, projeto: null, bu: null, mesref: null, anoref: null, codvertical: null };

  //populando select
  const { data } = useRequesitionGet(`api/portal/modulo-auth/environmentOptions`, () => ({ params: blankFilter }));

  //setar ciclo inicial
  if (data) {
    const ano = data[propControleReferencia][0]?.anoref ? data[propControleReferencia][0].anoref : new Date().getFullYear();
    const mes = data[propControleReferencia][0]?.mesref ? data[propControleReferencia][0].mesref : new Date().getMonth() + 1;

    if (
      !localStorage.getItem("Ano") ||
      !localStorage.getItem("Mes") ||
      !localStorage.getItem("Ciclo")
    ) {
      localStorage.setItem("Ano", ano);
      localStorage.setItem("Mes", mes);
      localStorage.setItem("Ciclo", [mes, ano]);

      currConfig = getLocalConfig();
    }

    //pegando ciclo atual no localstorage para filtrar o ciclo que será renderizado
    const cicloAtual = localStorage.getItem("Mes");

    const res = data[propControleReferencia].filter(
      (a) => a.mesref === parseInt(cicloAtual)
    );
    dispatch({ type: "ADD_CICLO", ciclo: res });
  }

  //button menu
  const toggleSidenav = (e) => {
    e.preventDefault();
    layoutHelpers.toggleCollapsed();
  };

  //show modal
  const ShowModal = () => {
    setDefaultModalShow(true);
  };

  //hide modal
  const onTopModalClose = () => {
    setDefaultModalShow(!defaultModalShow);
  };

  //funçao responsavel pelo filtro
  const ApplyConfig = async () => {
    localStorage.setItem("Pais", selectPais);
    localStorage.setItem("Projeto", selectProjeto);
    localStorage.setItem("Locale", selectIdioma);
    localStorage.setItem("Moeda", selectMoeda);
    localStorage.setItem("Mes", selectCiclo ? selectCiclo.split(",")[0] : "");
    localStorage.setItem("Ano", selectCiclo ? selectCiclo.split(",")[1] : "");
    localStorage.setItem("Ciclo", selectCiclo);
    localStorage.setItem("Bu", selectBusiness);
    localStorage.setItem("Vertical", selectVertical);
    localStorage.setItem("dscEmailUsuario", dscEmailFiltro);

    onTopModalClose();

    if (currConfig?.locale !== selectIdioma) {
      window.location.reload();

    } else {
      currConfig = getLocalConfig();

    }

    if (props.onChangeConfig) {
      // o pai deve realizar o mutate se necessário
      props.onChangeConfig({ componentName: 'SideBar', config: { ...currConfig } });
    }
  };

  // 230720-DIKE: desabilitando validação de seleção temporariamente para implementar ciclo de fatura
  // useEffect(() => {
  //   (async () => {
  //     if (selectPais !== currConfig?.pais) {
  //       let validData = await api.get("api/portal/modulo-auth/environmentOptions", { params: { ...blankFilter, codpais: selectPais } });

  //       ValidateDataSelection(validData.data);
  //     }
  //   })()
  // }, [selectPais]);

  // useEffect(() => {
  //   (async () => {
  //     if (selectProjeto !== currConfig?.projeto) {
  //       let validData = await api.get("api/portal/modulo-auth/environmentOptions", { params: { ...blankFilter, projeto: selectProjeto } });

  //       ValidateDataSelection(validData.data);
  //     }
  //   })()
  // }, [selectProjeto]);

  // useEffect(() => {
  //   (async () => {
  //     if (selectBusiness && selectBusiness !== currConfig?.bu) {
  //       let validData = await api.get("api/portal/modulo-auth/environmentOptions", { params: { ...blankFilter, bu: selectBusiness } });

  //       ValidateDataSelection(validData.data);
  //     }
  //   })()
  // }, [selectBusiness]);

  // useEffect(() => {
  //   (async () => {
  //     if (selectCiclo && selectCiclo !== currConfig?.ciclo) {
  //       let validData = await api.get("api/portal/modulo-auth/environmentOptions", { params: { ...blankFilter, mesref: selectCiclo.split(",")[0], anoref: selectCiclo.split(",")[1] } });

  //       ValidateDataSelection(validData.data);
  //     }
  //   })()
  // }, [selectCiclo]);

  // useEffect(() => {
  //   (async () => {
  //     if (selectVertical && selectVertical !== currConfig?.vertical) {
  //       let validData = await api.get("api/portal/modulo-auth/environmentOptions", { params: { ...blankFilter, codvertical: selectVertical } });

  //       ValidateDataSelection(validData.data);
  //     }
  //   })()
  // }, [selectVertical]);

  // useEffect(() => {
  //   (async () => {
  //     // validar se a moeda possui as cotações

  //   })()
  // }, [selectMoeda]);

  useEffect(() => {
    if (data && data[propControleReferencia]) setFlagContemCiclo(true);
  }, [data]);

  const ValidateDataSelection = async (validData) => {
    if (!validData.pais.includes(selectPais)) {
      setSelectPais("GLOBAL");
      toast.info(t`País inválido para a seleção escolhida, retornando para GLOBAL`, { position: "top-center" });
    }

    if (!validData.projeto.includes(selectProjeto)) {
      setSelectProjeto("GLOBAL");
      toast.info(t`Projeto inválido para a seleção escolhida, retornando para GLOBAL`, { position: "top-center" });
    }

    if (!validData.bu.includes(selectBusiness)) {
      setSelectBusiness("GLOBAL");
      toast.info(t`Unidade de négócio inválida para a seleção escolhida, retornando para GLOBAL`, { position: "top-center" });
    }

    if (!validData.vertical.includes(selectVertical)) {
      setSelectVertical("GLOBAL");
      toast.info(t`Vertical inválida para a seleção escolhida, retornando para GLOBAL`, { position: "top-center" });
    }

    if (!validData.moedaProjetos.includes(selectMoeda)) {
      setSelectMoeda(validData.moedaProjetos.shift());
      toast.info(t`Moeda inválida para a seleção escolhida, retornando para a primeira moeda válida`, { position: "top-center" });
    }

    //validData.ciclos = validdata[propControleReferencia].map(i => `${i.mesref},${i.anoref}`);
    // if (selectCiclo && !validData.ciclos.includes(selectCiclo)) {
    //   setSelectCiclo(validData.ciclos[0]);

    //   if (flagContemCiclo)
    //     toast.info(t`Ciclo inválido para a seleção escolhida, retornando para a primeira opção válida`, { position: "top-center" });
    // }
  };

  useEffect(() => {
    if (!logoSrc) {
      const obj = JSON.parse(localStorage.getItem("ls-objTemplate"));

      if (obj && Object.keys(obj).length > 0 && obj["logo-src"]) {
        setLogoSrc(obj["logo-src"]);
        return;
      }

      setLogoSrc(Logo);
    }
  }, [logoSrc])

  useEffect(() => {
    if (!selectedCliente) {
      const objToken = parseJwt(localStorage.getItem("Token"));

      if (objToken) {
        const ddlCliente = { label: objToken?.NomCliente, value: parseInt(objToken.CodCliente) };

        setSelectedClienteLocal(ddlCliente);
        dispatch(setSelectedCliente(ddlCliente));
        localStorage.setItem("selectedCliente", JSON.stringify(ddlCliente));
      }
    }
  }, [dispatch, selectedCliente])

  useEffect(() => {
    const objToken = parseJwt(localStorage.getItem("Token"));
    if (objToken) {
      setFlagMostrarFiltroEmail(
        objToken?.role?.find(e => e === 'admin' || e === 'emailFiltroGlobal-rw') ? true : false
      );
    }
  }, [] )

  const homeOnClick = async () => {
    // const fetchData = async () => {
    //   try {
    //     const response = await api.post("api/login/refresh-token");
    //     const { authed } = response.data.result;

    //     localStorage.setItem("Token", authed);
    //   } catch (e) {
    //     console.log(e);
    //   }
    // };

    // const dtaRefreshToken = localStorage.getItem("ls-dtaRefreshToken");

    // // apenas dispara request p/ endpoint 'refresh-token' em um intervalo de 3 dias
    // if (!dtaRefreshToken || new Date().getTime() > (new Date(dtaRefreshToken).getTime() + 259200000)) {
    //   localStorage.setItem("ls-dtaRefreshToken", new Date());
    //   await fetchData();
    // }

    history.push(JSON.parse(localStorage.getItem("Homepath")) ?? "/dashboard/dashboard-telecom");
  }

  useEffect(() => {
    if (data) {
      const listaVerticalAux = data?.vertical ?? [];
      listaVerticalAux.push("Telecom");

      setListaVertical(listaVerticalAux);
    }
  }, [data])

  return (
    <Navbar
      expand="lg"
      className="layout-navbar align-items-lg-center  container-p-x"
    >
      <div className="img-responsive d-lg-none p-2" style={{ width: "160px" }}>
        <button className="homepage">
          <img src={logoSrc} alt="BTB telecom" className="logoSizeInterno" onClick={homeOnClick} />
        </button>
      </div>

      <div className="d-none d-lg-block" style={{ width: "160px" }}>
        <button className="homepage">          
          <img src={logoSrc} alt="BTB telecom" className="logoSizeInterno" onClick={homeOnClick} />
        </button>        
      </div>

      {props.sidenavToggle && (
        <Nav className="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto">
          <Nav.Item
            as="a"
            className="nav-link px-0 mr-lg-4"
            href="#toggle"
            onClick={toggleSidenav}
          >
            <i className="ion ion-md-menu text-large ml-3 mt-1 align-middle"></i>
          </Nav.Item>
        </Nav>
      )}
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Nav className="align-items-lg-center ml-auto">
          {/* Modal template */}
          <i
            className="ion ion-ios-settings text-big cursor-pointer pr-1"
            onClick={() => ShowModal()}
          ></i>
          {currConfig?.mes ? (
            <Fragment>
              {/* aqui aqui */}
              <Badge variant={"secondary"} className="mr-2">
                {currConfig?.pais && currConfig?.pais !== 'GLOBAL' && currConfig?.pais?.toLocaleUpperCase()}
              </Badge>
              <Badge variant={"secondary"} className="mr-2">
                {currConfig?.projeto && currConfig?.projeto !== 'GLOBAL' && currConfig?.projeto?.toLocaleUpperCase()}
              </Badge>
              <Badge variant={"secondary"} className="mr-2">
                {currConfig?.bu && currConfig?.bu !== 'GLOBAL' && currConfig?.bu?.toLocaleUpperCase()}
              </Badge>
              <Badge variant={"secondary"} className="mr-2">
                {currConfig?.locale && currConfig?.locale?.toLocaleUpperCase()}
              </Badge>
              <Badge variant={"secondary"} className="mr-2">
                {currConfig?.moeda && currConfig?.moeda?.toLocaleUpperCase()}
              </Badge>
              <Badge variant={"secondary"} className="mr-2">
                {currConfig?.vertical && currConfig?.vertical !== 'GLOBAL' && currConfig?.vertical?.toLocaleUpperCase()}
              </Badge>
              <Badge variant={"secondary"} className="mr-2">
                {currConfig?.mes && Meses(currConfig?.mes).toLocaleUpperCase()} - {currConfig?.ano && currConfig?.ano}
              </Badge>
              <Badge variant={"secondary"} className="mr-2">
                {currConfig?.dscEmailUsuario && currConfig?.dscEmailUsuario?.toLocaleUpperCase()}
              </Badge>
            </Fragment>
          ) : (
            <LoadingBadge />
          )}
          
          {!defaultModalShow && <ToastContainer theme="colored"/>}

          <Modal show={defaultModalShow} size={"sm"} onHide={onTopModalClose}>
            <Modal.Header closeButton>
              <Modal.Title as="h5">
                {t`Filtro Global`}
                <span className="font-weight-light">
                  &nbsp;{t`Configurador`}
                </span>
                <br />
                <small className="text-muted">
                  {t`Os filtros abaixo serão aplicados em toda plataforma`}
                </small>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* {defaultModalShow && <ToastContainer theme="colored"/>} */}
              <Form.Group>
                <Form.Label>{t`País`}</Form.Label>
                <select
                  className="custom-select mt-1"
                  value={selectPais}
                  onChange={(e) => setSelectPais(e.target.value)}
                >
                  {data &&
                    data.pais.map((item) => (
                      <option key={item} value={item}>
                        {c('countries').t([item])}
                      </option>
                    ))}
                </select>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t`Projeto`}</Form.Label>
                <select
                  className="custom-select mt-1"
                  value={selectProjeto}
                  onChange={(e) => setSelectProjeto(e.target.value)}
                >
                  {data &&
                    data.projeto.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                </select>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t`Unidade de Negócio`}</Form.Label>
                <select
                  className="custom-select mt-1"
                  value={selectBusiness}
                  onChange={(e) => setSelectBusiness(e.target.value)}
                >
                  {data &&
                    data.bu.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                </select>
              </Form.Group>
              {flagMostrarFiltroEmail ? 
              <Form.Group>
                <Form.Label>{t`E-mail`}</Form.Label>
                <Form.Control
                  type="text"
                  value={dscEmailFiltro}
                  placeholder={t`E-mail`}
                  onChange={(e) => setDscEmailFiltro(e.target.value)}
                />
              </Form.Group>
              : <></>}
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>{t`Idioma`}</Form.Label>
                  <select
                    className="custom-select mt-1"
                    value={selectIdioma}
                    onChange={(e) => setSelectIdioma(e.target.value)}
                  >
                    <option value="pt-br">PT-BR</option>
                    <option value="en">EN</option>
                    <option value="fr">FR</option>
                    <option value="es">ES</option>
                  </select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>{t`Moeda`}</Form.Label>
                  <select
                    className="custom-select mt-1"
                    value={selectMoeda}
                    onChange={(e) => setSelectMoeda(e.target.value)}
                  >
                    {data &&
                      data.moedaProjetos.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                  </select>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} className="mb-0">
                  <Form.Label>{t`Vertical`}</Form.Label>
                  <select
                    className="custom-select mt-1"
                    value={selectVertical}
                    onChange={(e) => setSelectVertical(e.target.value)}
                  >
                    {listaVertical &&
                      listaVertical.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                  </select>
                </Form.Group>
                <Form.Group as={Col} className="mb-0">
                  <Form.Label>{t`Ciclo de Faturamento`}</Form.Label>
                  <select
                    className="custom-select mt-1"
                    value={selectCiclo}
                    onChange={(e) => setSelectCiclo(e.target.value)}
                  >
                    {data &&
                      data[propControleReferencia].map((item) => (
                        <option
                          key={item.mesref}
                          value={[item.mesref, item.anoref]}
                        >
                          {item.label}
                        </option>
                      ))}
                  </select>
                </Form.Group>
              </Form.Row>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="default" onClick={onTopModalClose}>
                {t`Fechar`}
              </Button>
              <Button
                variant="primary"
                onClick={ApplyConfig}
              >{t`Aplicar`}</Button>
            </Modal.Footer>
          </Modal>

          {/* Divider */}
          <div className="nav-item d-none  d-lg-block text-big font-weight-light line-height-1 opacity-25 ml-2">
            |
          </div>

          <span className="px-1 ml-lg-2 ml-2 ml-lg-0 text-black-sidebar">
            {currConfig?.usuario}
          </span>

          {/* Divider */}
          <div className="nav-item d-none  d-lg-block text-big font-weight-light line-height-1 opacity-25 ml-1">
            |
          </div>

          {/*Nome do Cliente*/}
          <Dropdown as={Nav.Item} className="demo-navbar-user">
            <Dropdown.Toggle as={Nav.Link}>
              <span className="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                <span className="app-brand-logo demo bg-primary">
                  <strong className="text-white">
                    {currConfig?.cliente?.substr(0, 2).toLocaleUpperCase()}
                  </strong>
                </span>

                <span className="px-1 mr-lg-2 ml-1 ml-lg-0 text-black-sidebar">
                  {currConfig?.cliente && currConfig?.cliente}
                </span>
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {!JSON.parse(localStorage.getItem("flagSSO"))
                ? <Dropdown.Item onClick={() => history.push('/alterar-senha')}>
                  <i className="fa fa-unlock-alt text-danger"></i> &nbsp;
                  {t`Alterar senha`}
                </Dropdown.Item> : <></>}
              <Dropdown.Item onClick={() => LogOut(history)}>
                <i className="ion ion-ios-log-out text-danger"></i> &nbsp;
                {t`Sair`}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
