import { t } from "ttag";

export const Meses = mes => {
  if (mes === 1 || mes === "1") return t`Janeiro`;
  if (mes === 2 || mes === "2") return t`Fevereiro`;
  if (mes === 3 || mes === "3") return t`Março`;
  if (mes === 4 || mes === "4") return t`Abril`;
  if (mes === 5 || mes === "5") return t`Maio`;
  if (mes === 6 || mes === "6") return t`Junho`;
  if (mes === 7 || mes === "7") return t`Julho`;
  if (mes === 8 || mes === "8") return t`Agosto`;
  if (mes === 9 || mes === "9") return t`Setembro`;
  if (mes === 10 || mes === "10") return t`Outubro`;
  if (mes === 11 || mes === "11") return t`Novembro`;
  if (mes === 12 || mes === "12") return t`Dezembro`;
};

export default Meses;
