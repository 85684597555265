export const setSelectedCliente = (payload) => ({
  type: "SET_SELECTED_CLIENTE",
  payload,
});

export const setCodCliente = (payload) => ({
  type: "SET_CODCLIENTE",
  payload
});

export const enableTodos = (payload) => ({
  type: "ENABLE_TODOS",
  payload
});

export const disableTodos = (payload) => ({
  type: "DISABLE_TODOS",
  payload
})

export const populaListaPathCliTodos = (payload) => ({
  type: "SET_PATH_LISTA_CLI_TODOS",
  payload
})