const INITIAL_STATE = {
  telephony: "",
};

export default function DetalhesDaFatura(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "TELEFONIA":
      return { ...state, telephony: action.typeTelephony };
    default:
      return state;
  }
}
