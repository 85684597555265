import React, { Fragment, useState, useEffect } from 'react';

import { t } from "ttag";
import Sidenav from "../../vendor/libs/sidenav";
import api from "../../services/apiBender";
import ElementLoading from "../../components/Loading/ElementLoading";
import { getErrorMessage } from "../../services/shared";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { setPropControleReferencia } from "../../store/actions/viewMode/viewModeAction";

const Menu = (props) => {
    const dispatch = useDispatch();

    const [jsonMenu, setJsonMenu] = useState(JSON.parse(sessionStorage.getItem("ss-menu")));
    const [jsxMenu, setJsxMenu] = useState();
    const [flagMenuLoading, setFlagMenuLoading] = useState(false);

    const layoutSidenavClasses = () => {
        return props.position !== "horizontal"
            ? "layout-sidenav"
            : "layout-sidenav-horizontal bg-sidenav-theme container-p-x flex-grow-0";
    }

    useEffect(() => {
        const setPropMesAnoRef = async(menuJson) => {
            // 230720-DIKE: controle da referência do mês/ano ref tempoariamente aqui
            // obter todas as rotas e se o usuário em questão tiver acesso a rota /dashboard/controlecontas
            // o controle do combo será feito pela prop cicloFatura e não cicloProjeto (default)
            //
            // função para "achatar" recursivamente toda a estrutura em um único array de consulta
            const doFlatJsonMenu = (r) => r.reduce((c, n) => [ ...c, (({ listaChildrens, ...n }) => n)(n), ...((n.listaChildrens) ? doFlatJsonMenu(n.listaChildrens) : []) ], []);

            const jsonMenuAuxAchatado = doFlatJsonMenu(menuJson);

            if (jsonMenuAuxAchatado.some(i => i.dscSrcPath?.toLowerCase() === '/dashboard/controlecontas')) {
                dispatch(setPropControleReferencia('cicloFatura'));
            } else {
                dispatch(setPropControleReferencia('cicloProjeto'));
            }
        }

        const obtemMenu = async () => {
            setFlagMenuLoading(true);

            // menu default
            let jsonMenuAux = [
                {
                    dscTitle: "Dashboard",
                    flagMenu: true,
                    dscIcon: "sidenav-icon lnr lnr-pie-chart text-primary",
                    flagIconBeta: false,
                    numNivel: 0,
                    numOrdemExibicao: 0,
                    listaChildrens: [
                        {
                            dscTitle: "Telecom",
                            flagMenu: false,
                            flagIconBeta: false,
                            numNivel: 1,
                            numOrdemExibicao: 0,
                            dscSrcPath: "/dashboard/dashboard-telecom"
                        }
                    ]
                },
                {
                    dscTitle: "Telecom",
                    flagMenu: true,
                    dscIcon: "sidenav-icon lnr lnr-pie-chart text-primary",
                    flagIconBeta: false,
                    numNivel: 0,
                    numOrdemExibicao: 1,
                    listaChildrens: [
                        {
                            dscTitle: "Faturamento",
                            flagMenu: false,
                            flagIconBeta: false,
                            numNivel: 1,
                            numOrdemExibicao: 0,
                            dscSrcPath: "/faturamento"
                        },
                        {
                            dscTitle: "Relatórios",
                            flagMenu: true,
                            flagIconBeta: true,
                            numNivel: 1,
                            numOrdemExibicao: 1,
                            listaChildrens: [
                                {
                                    dscTitle: "Classe de Serviço",
                                    flagMenu: false,
                                    flagIconBeta: false,
                                    numNivel: 2,
                                    numOrdemExibicao: 0,
                                    dscSrcPath: "/classe-de-servico"
                                },
                                {
                                    dscTitle: "Consolidado por Consumo",
                                    flagMenu: false,
                                    flagIconBeta: false,
                                    numNivel: 2,
                                    numOrdemExibicao: 1,
                                    dscSrcPath: "/consolidado-por-consumo"
                                },
                                {
                                    dscTitle: "Consolidado por Centro Custo",
                                    flagMenu: false,
                                    flagIconBeta: false,
                                    numNivel: 2,
                                    numOrdemExibicao: 2,
                                    dscSrcPath: "/consolidado-centro-custo"
                                },
                                {
                                    dscTitle: "Consolidado por Perfil",
                                    flagMenu: false,
                                    flagIconBeta: false,
                                    numNivel: 2,
                                    numOrdemExibicao: 3,
                                    dscSrcPath: "/consolidado-por-perfil"
                                },
                            ]
                        }
                    ]
                }
            ]

            try {
                const { data: jsonMenuResponse } = await api.get("v2/menuPortal");
                
                if (jsonMenuResponse)
                    jsonMenuAux = jsonMenuResponse;

                sessionStorage.setItem("ss-menu", JSON.stringify(jsonMenuAux));
                setJsonMenu(jsonMenuAux);

                setJsxMenu(ConstroiMenu(jsonMenuAux));
                setFlagMenuLoading(false);
                setPropMesAnoRef(jsonMenuAux);
            } catch (error) {
                console.log(`error: ${getErrorMessage(error)}`);
                // toast.error(getErrorMessage(error), { autoClose: 8000 });
            }
        }

        const ConstroiMenu = (objMenu) => {
            return (
                <>
                    {
                        objMenu.map(e => (
                            <Fragment key={e?.dscTitle}>
                                {e.flagMenu
                                    ?
                                    <>

                                        <Sidenav.Menu
                                            icon={e?.dscIcon}
                                            linkText={t([e?.dscTitle])}
                                            badgeText={e?.flagIconBeta ? `${t`Beta`}` : null}
                                            badgeVariant={e?.flagIconBeta ? "primary text-white" : null}
                                        >
                                            {ConstroiMenuChildrens(e?.listaChildrens)}
                                        </Sidenav.Menu>
                                    </> : <></>}
                            </Fragment>
                        ))
                    }
                </>
            );
        }

        const ConstroiMenuChildrens = (listaChildrens) => {
            return (
                <>
                    {listaChildrens && listaChildrens.map(c =>
                        <Fragment key={c.dscTitle}>
                            {c.flagMenu
                                ? <>{ConstroiMenu([c])}</>
                                : <Sidenav.RouterLink
                                    to={c?.dscSrcPath}
                                    badgeText={c?.flagIconBeta ? `${t`Beta`}` : null}
                                    badgeVariant={c?.flagIconBeta ? "primary text-white" : null}>{t([c?.dscTitle])}</Sidenav.RouterLink>}
                        </Fragment>)}
                </>
            );
        }

        if (!jsxMenu) {
            const jsxMenuSStorage = jsonMenu ? [...jsonMenu] : null;

            if (jsxMenuSStorage && jsxMenuSStorage.length > 0) {
                setJsxMenu(ConstroiMenu(jsxMenuSStorage));
                setPropMesAnoRef(jsxMenuSStorage);
                return;
            }

            obtemMenu();
        }

    }, [jsonMenu, jsxMenu])

    return (
        <>
            <Sidenav orientation={props.position} className={layoutSidenavClasses()}>
                <div className={`sidenav-inner ${props.position !== "horizontal" ? "py-1 bg-sidenav-theme" : ""}`}>
                    <ToastContainer theme="colored"/>
                    {flagMenuLoading ? <ElementLoading /> : jsxMenu}
                </div>
            </Sidenav>
        </>
    );
}

export default Menu;