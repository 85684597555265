const INITIAL_STATE = {
  selectedCliente: JSON.parse(sessionStorage.getItem("selectedCliente") || localStorage.getItem("selectedCliente") || null)
};

const clienteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_SELECTED_CLIENTE":
      sessionStorage.setItem("selectedCliente", JSON.stringify(action.payload));
      if (action.payload?.value !== '' && action.payload?.value !== "null") { // salva no localstorage somente se for diferente de "TODOS"
        localStorage.setItem("selectedCliente", JSON.stringify(action.payload));
      }
      return {
        ...state,
        isPendingLabel: false,
        selectedCliente: action.payload,
      };
    case "SET_CODCLIENTE":
      sessionStorage.setItem("selectedCliente", JSON.stringify({ label: `...`, value: action.payload }));
      if (action.payload !== '' && action.payload !== "null") {
        localStorage.setItem("selectedCliente", JSON.stringify({ label: `...`, value: action.payload }));
      }
      return {
        ...state,
        isPendingLabel: true,
        pendingCodCliente: action.payload
      }
    case "ENABLE_TODOS":
      return {
        ...state,
        enableTodos: true
      }
    case "DISABLE_TODOS":
      return {
        ...state,
        enableTodos: false
      }
    case "SET_PATH_LISTA_CLI_TODOS":
      const listaAtual = JSON.parse(localStorage.getItem("ls-listaPathCliTodos")) ?? [];
      const path = action.payload;

      if (listaAtual && !listaAtual.find(e => e === path)) {
        listaAtual.push(path);

        localStorage.setItem("ls-listaPathCliTodos", JSON.stringify(listaAtual));
      }

      return {
        ...state,
        listaPathCliTodos: listaAtual
      }      
    default:
      return state;
  }
};

export default clienteReducer;
