import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_DNS_CORE}/`,
});

//intercepta requisiçoes da api
api.interceptors.request.use(async (config) => {
  const TOKEN = await window.localStorage.getItem("Token");

  try {
    config.headers.Authorization = `Bearer ${TOKEN}`;

    return config;
  } catch (err) {
    console.log(err);
  }
});

// api.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (401 === error.response.status) {
//       cookie.remove("authed", { path: "/" });
//       cookie.remove("locale", { path: "/" });
//       cookie.remove("cliente", { path: "/" });
//       cookie.remove("moeda", { path: "/" });
//       cookie.remove("role", { path: "/" });
//       localStorage.clear();

//       window.location = "/";
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );

//retorna query string (parametro na baseUrl)
api.interceptors.request.use((config) => {
  config.params = {
    moeda: localStorage.getItem("Moeda"),
    locale: localStorage.getItem("Locale"),
    projeto: localStorage.getItem("Projeto"),
    anoref: localStorage.getItem("Ano"),
    mesref: localStorage.getItem("Mes"),
    bu: localStorage.getItem("Bu"),
    codvertical: localStorage.getItem("Vertical"),
    ...config.params
  };
  return config;
});

export default api;
