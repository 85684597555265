import axios from "axios";
import cookie from "react-cookies";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_DNS_CORE}/`,
});

// ${process.env.REACT_APP_DNS_CORE}
//intercepta requisiçoes da api
api.interceptors.request.use(async (config) => {
  const TOKEN = await window.localStorage.getItem("Token");

  try {
    config.headers.Authorization = `Bearer ${TOKEN}`;

    return config;
  } catch (err) {
    console.log(err);
  }
});

// api.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (401 === error.response.status) {
//       cookie.remove("authed", { path: "/" });
//       cookie.remove("locale", { path: "/" });
//       cookie.remove("cliente", { path: "/" });
//       cookie.remove("moeda", { path: "/" });
//       cookie.remove("role", { path: "/" });
//       localStorage.clear();

//       window.location = "/";
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );

//retorna query string (parametro na baseUrl)
api.interceptors.request.use((config) => {
  let nomprojeto = config.nomprojeto
    ? config.nomprojeto
    : localStorage.getItem("Projeto") &&
      localStorage.getItem("Projeto") !== "GLOBAL"
    ? localStorage.getItem("Projeto")
    : null;
  let nomunidadenegocio = config.nomunidadenegocio
    ? config.nomunidadenegocio
    : localStorage.getItem("Bu") && localStorage.getItem("Bu") !== "GLOBAL"
    ? localStorage.getItem("Bu")
    : null;
  let codvertical = config.codvertical
    ? config.codvertical
    : localStorage.getItem("Vertical") &&
      localStorage.getItem("Vertical") !== "GLOBAL"
    ? localStorage.getItem("Vertical")
    : null;
  config.params = {
    ...config.params,
    nomprojeto,
    nomunidadenegocio,
    codvertical,
    moeda: localStorage.getItem("Moeda"),
    locale: localStorage.getItem("Locale"),
  };
  return config;
});

export default api;
